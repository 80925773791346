import { Breadcrumb, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TabsView from "../../components/TabView/TabsView";
import TableView from "../../components/TableViews/TableView";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import Sc from "./Dashboard.module.scss";

import { useNavigate } from "react-router-dom";
import AddClientModal from "../../components/Modals/AddClientModal";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
  plusIconWhite,
} from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";
import { GetCurrentUser } from "../../utils/utilAuth";
import Styles from "./Dashboard.module.scss";

const ClientsListing = ({
  ClientSessions,
  AllSessions,
  View,
  setdashboardView,
  RightSidebar,
  dashboardView,
  Panel = false,
  tabOf,
  units,
  director,
  AdminSupporter,
}) => {
  const navigate = useNavigate();
  const user = GetCurrentUser();
  const [addClientModalView, setAddClientModalView] = useState(false);
  const [addTechnicianModalView, setaddTechnicianModalView] = useState(false);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [clientCreds, setClientCreds] = useState();
  const [techCreds, setTechCreds] = useState();
  const [loading, setIsLoading] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [currentView, setCurrentView] = useState(View);
  const [sideBarData, setSideBarData] = useState();
  const [actionDoneSide, setActionDoneSide] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        setIsLoading(true);
        const getTableData = async ({ role, page, search, status }) => {
          let newStatus = "";
          switch (status) {
            case "archived":
              newStatus = "archive";
              break;
            case "unarchived":
              newStatus = "active";
              break;
            case "pending_request":
              newStatus = "";
          }
          const queryParams = {
            role: role,
            q: search ?? "",
            page: page,
            per_page: 10,
            status:
              status === "clients" || status === "technicians"
                ? "active"
                : newStatus,
            pending: status === "pending_request" ? true : "",
          };
          const queryString = new URLSearchParams(queryParams).toString();

          // setTableData(TableDataClient);
          try {
            const response = await httpClient.get(
              `api/v1/users/?${queryString}`
            );
            setTableData(response.data);
            setIsLoading(false);

            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              setIsLoading(false);
              localStorage.removeItem(`persist:root`);

              logout();
              window.open("/dashboardlogin", "_self");
              return;
            } else if (
              error.message ===
              "Cannot read properties of null (reading 'user')"
            ) {
              window.open("/dashboardlogin", "_self");
            }
            setIsLoading(false);
            createNotification("error", error?.response?.data?.response?.error);
            console.log("Something Went wrong", error);
          }

          setIsLoading(false);
        };

        getTableData({
          page: currentPage,
          role: tabOf,
          search: searchText,
          status: currentView,
        });
      },
      searchText ? 1000 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, searchText, currentView, actionDone, View]);

  const getActiveTab = (tab) => {
    setCurrentView(tab);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentView]);

  useEffect(() => {
    const currentDateTime = fetchFormattedDateTime();
    const getData = async () => {
      await sleep(2000); // TO REDUCE DB LOAD
      try {
        const response = await httpClient.get(
          `/api/v1/users/user_listing_summary`,
          { params: { role: tabOf, current_time: currentDateTime } }
        );
        // setTableData(response.data);
        setSideBarData(response.data);
        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          setIsLoading(false);
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        } else if (
          error.message === "Cannot read properties of null (reading 'user')"
        ) {
          window.open("/dashboardlogin", "_self");
        }
        // console.log(JSON.stringify(error), "data");
        setIsLoading(false);
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    if (!director) {
      getData();
    }
  }, [tabOf, actionDoneSide]);

  useEffect(() => {
    const currentDateTime = fetchFormattedDateTime();
    const getData = async () => {
      await sleep(2000); // TO REDUCE DB LOAD
      try {
        const response = await httpClient.get(
          `/api/v1/users/user_listing_summary`,
          { params: { role: tabOf, current_time: currentDateTime } }
        );
        // setTableData(response.data);
        setSideBarData(response.data);
        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          setIsLoading(false);
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        } else if (
          error.message === "Cannot read properties of null (reading 'user')"
        ) {
          window.open("/dashboardlogin", "_self");
        }
        // console.log(JSON.stringify(error), "data");
        setIsLoading(false);
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    if (tabOf === "director") {
      getData();
    }
  }, [tabOf, actionDoneSide]);

  const TabsDataClientSessions = ClientSessions
    ? [
        {
          id: Math.random(),
          title: "Week",
          key: "week",
        },
        {
          id: Math.random(),
          title: "Month",
        },
      ]
    : units
    ? [
        {
          id: Math.random(),
          title: "Original Target",
          key: "original_target",
        },
        {
          id: Math.random(),
          title: "As of today",
          key: "today",
        },
        {
          id: Math.random(),
          title: "Updated target",
          key: "updated_target",
        },
      ]
    : director
    ? [
        {
          id: Math.random(),
          title: tabOf === "director" && "Directors",
          key: "unarchived",
        },
        {
          id: Math.random(),
          title: "Pending Requests",
          key: "pending_request",
        },
        {
          id: Math.random(),
          title: "Archived",
          key: "archived",
        },
      ]
    : AdminSupporter
    ? [
        {
          id: Math.random(),
          title: tabOf === "admin_supporter" && "Admin Supporters",
          key: "unarchived",
        },
        {
          id: Math.random(),
          title: "Pending Requests",
          key: "pending_request",
        },
        {
          id: Math.random(),
          title: "Archived",
          key: "archived",
        },
      ]
    : [
        {
          id: Math.random(),
          title: tabOf === "client" ? "Clients" : "Technicians",
          key: "unarchived",
        },
        {
          id: Math.random(),
          title: "Pending Requests",
          key: "pending_request",
        },
        {
          id: Math.random(),
          title: "Archived",
          key: "archived",
        },
      ];
  const getTableView = (CurrentView) => {
    switch (CurrentView) {
      case "DailySessions":
        return <TableView View={"Panel"} currentPage={currentPage} />;
      case "unarchived":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            tableData={tableData?.users}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      case "Week":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={"AllSessionListing"}
            tableData={tableData?.users}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      case "Month":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={"AllSessionListing"}
            setCurrentPage={setCurrentPage}
            tableData={tableData?.users}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      case "pending_request":
        return (
          <TableView
            Pagination={true}
            setdashboardView={setdashboardView}
            View={CurrentView}
            setCurrentPage={setCurrentPage}
            tableData={tableData?.users}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );

      case "archived":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            setCurrentPage={setCurrentPage}
            tableData={tableData?.users}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      default:
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            tableData={tableData?.users}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
    }
  };

  const handleAddClient = async () => {
    try {
      if (clientCreds) {
        const res = await httpClient.post("users/send_client_login_creds", {
          user: { ...clientCreds, role: tabOf },
        });
        if (res.data) {
          if (currentView === "pending_request") {
            setActionDone(!actionDone);
          }
          setActionDoneSide(!actionDoneSide);
          createNotification("success", "Client Added");
          setModalLoading(false);
          setAddClientModalView(false);
        }
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        setAddClientModalView(false);

        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      // setAddClientModalView(false);
      setModalLoading(false);

      return false;
    }
  };
  const handleAddTech = async () => {
    try {
      if (techCreds) {
        const res = await httpClient.post("users/send_password_email", {
          user: { ...techCreds, role: tabOf },
        });
        if (res.data) {
          setActionDoneSide(!actionDoneSide);
          if (currentView === "pending_request") {
            setActionDone(!actionDone);
          }
          {
            tabOf === "director"
              ? createNotification("success", "Director Added")
              : tabOf === "admin_supporter"
              ? createNotification("success", "Admin Supporter Added")
              : createNotification("success", "Technician Added");
          }
          setModalLoading(false);
          setaddTechnicianModalView(false);
        }

        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response?.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      setModalLoading(false);

      return false;
    }
  };
  const [modalLoading, setModalLoading] = useState(false);
  return (
    <section className={`${Sc.ClientListingContainer}`}>
      <section
        className={`${Sc.ClientListing}`}
        style={{ position: "relative" }}
      >
        {dashboardView === "Units" && (
          <div
            className={`mb-1 ${Styles.ProfileContainer__LeftSidebar_Header}`}
            style={{ paddingBottom: "8px" }}
          >
            <div
              onClick={() => {
                setdashboardView("Client");
                navigate(-1);
              }}
              className="lm-nav-arrow"
            >
              {" "}
              {arrowLeftPurple}
            </div>
            <Breadcrumb
              separator={arrowRightBreadcrumb}
              items={[
                {
                  title: "Clients",
                },
                {
                  title: "Client Profile  ",
                  // href: "",
                },
                {
                  title: "Units",
                  // href: "",
                },
              ]}
            />
          </div>
        )}
        <div className={`${Sc.ClientListing__Wrapper}`}>
          <div className={Sc.ClientListing__Header}>
            {ClientSessions && (
              <h4 className="f-24 f-w-700 lh-25 clr-dark-grey">
                Sessions completed
              </h4>
            )}
            <div
              className={`${
                Sc.ClientListing__Header_InputSection
              }  d-flex gap-25 ${
                AllSessions && "w-100 justify-content-between"
              } `}
            >
              {ClientSessions ? (
                <TabsView
                  activeDefault={TabsDataClientSessions[0].key}
                  TabsData={TabsDataClientSessions}
                  getActiveTab={getActiveTab}
                  Container={false}
                  setIsLoading={setIsLoading}
                  setSearchText={setSearchText}
                  setCurrentView={setCurrentView}
                />
              ) : (
                <TabsView
                  activeDefault={TabsDataClientSessions[0].key}
                  TabsData={TabsDataClientSessions}
                  getActiveTab={getActiveTab}
                  Container={true}
                  setIsLoading={setIsLoading}
                  setSearchText={setSearchText}
                  setCurrentView={setCurrentView}
                />
              )}
              <TextFieldFormik
                wrapperClassName={`Search-Input-Wrapper ${
                  ClientSessions ? "w-100" : "w-50"
                }`}
                values={searchText}
                handleChange={(e) => {
                  setCurrentPage(1);
                  setSearchText(e.target.value);
                }}
                type="search"
                placeholder="Search"
              />
            </div>
          </div>
          {loading ? <Spin></Spin> : getTableView(currentView)}
        </div>
      </section>
      {RightSidebar && (
        <section className={Sc.ClientListing__RightSidebar}>
          {tabOf === "units" && (
            <>
              <div className={Sc.ClientListing__RightSidebar_board}>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">74</span>
                  <span className="clr-dark-grey f-15 f-w-600 lh-20">
                    Remaining Units
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    1794
                  </span>
                  <span className="clr-dark-grey f-15 f-w-600 lh-20">
                    Authorized Units
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <p className="clr-dark-grey f-15 f-w-600 lh-20">
                    Authorization Start Date
                  </p>
                  <span className="clr-text-light-gray f-13 lh-20">
                    19 June 2023
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <p className="clr-dark-grey f-15 f-w-600 lh-20">
                    Authorization End Date
                  </p>
                  <span className="clr-text-light-gray f-13 lh-20">
                    19 June 2023
                  </span>
                </div>
              </div>
              <Button
                // onClick={() => {
                //   setAddClientModalView(true);
                // }}
                className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
              >
                {plusIconWhite} Add Units
              </Button>
            </>
          )}
          {tabOf === "client" && (
            <>
              <div className={Sc.ClientListing__RightSidebar_board}>
                <div className={Sc.ClientListing__RightSidebar_clientUnits}>
                  <span className={Sc.ClientListing__RightSidebar_countNumber}>
                    {sideBarData?.clients_summary?.clients_count}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Total Clients
                  </span>
                </div>
                <div className={Sc.ClientListing__RightSidebar_clientUnits}>
                  <span className={Sc.ClientListing__RightSidebar_countNumber}>
                    {sideBarData?.clients_summary?.next_30_day_expiry}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Client Units Expiring in the Next 30 Days
                  </span>
                </div>
                <div className={Sc.ClientListing__RightSidebar_clientUnits}>
                  <span className={Sc.ClientListing__RightSidebar_countNumber}>
                    {sideBarData?.clients_summary?.onboarding_requests}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Onboarding Requests Sent
                  </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setAddClientModalView(true);
                }}
                className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
              >
                {plusIconWhite} Add Client
              </Button>
            </>
          )}
          {tabOf === "technician" && (
            <>
              <div className={Sc.ClientListing__RightSidebar_board}>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.technician_summary?.total_technicians}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Total Technicians
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.technician_summary?.active_technicians}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Active Technicians
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.technician_summary?.booked_technician}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Booked Technicians
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.technician_summary?.available_technician}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Available Technician{" "}
                  </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setaddTechnicianModalView(true);
                }}
                className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
              >
                {plusIconWhite} Add Technician
              </Button>
            </>
          )}

          {tabOf === "director" && (
            <>
              <div className={Sc.ClientListing__RightSidebar_board}>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.user_summary?.director_count}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Total Directors
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.user_summary?.pending_directors}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Pending Directors
                  </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setaddTechnicianModalView(true);
                }}
                className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
                style={{ whiteSpace: "nowrap" }}
              >
                {plusIconWhite} Add Director
              </Button>
            </>
          )}

          {tabOf === "admin_supporter" && (
            <>
              <div className={Sc.ClientListing__RightSidebar_board}>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.user_summary?.admin_supporter_count}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Total Admin Supporters
                  </span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                    {" "}
                    {sideBarData?.user_summary?.pending_admin_supporters}
                  </span>
                  <span className="clr-dark-grey f-15 lh-20 f-w-600">
                    Pending Admin Supporters
                  </span>
                </div>
              </div>
              <Button
                onClick={() => {
                  setaddTechnicianModalView(true);
                }}
                className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
                style={{ whiteSpace: "nowrap" }}
              >
                {plusIconWhite} Add Admin Supporter
              </Button>
            </>
          )}
        </section>
      )}
      {tabOf === "client" ? (
        <AddClientModal
          AddingEntity="Client"
          show={addClientModalView}
          onHide={() => {
            setAddClientModalView(false);
          }}
          onHandleAdd={() => {
            handleAddClient();
          }}
          setCreds={setClientCreds}
          creds={clientCreds}
          modalLoading={modalLoading}
          setLoadingModal={setModalLoading}
        />
      ) : tabOf === "director" ? (
        <AddClientModal
          AddingEntity="Director"
          show={addTechnicianModalView}
          onHide={() => {
            setaddTechnicianModalView(false);
          }}
          setCreds={setTechCreds}
          creds={techCreds}
          onHandleAdd={() => {
            handleAddTech();
          }}
          modalLoading={modalLoading}
          setLoadingModal={setModalLoading}
        />
      ) : tabOf === "admin_supporter" ? (
        <AddClientModal
          AddingEntity="Admin Supporter"
          show={addTechnicianModalView}
          onHide={() => {
            setaddTechnicianModalView(false);
          }}
          setCreds={setTechCreds}
          creds={techCreds}
          onHandleAdd={() => {
            handleAddTech();
          }}
          modalLoading={modalLoading}
          setLoadingModal={setModalLoading}
        />
      ) : (
        <AddClientModal
          AddingEntity="Technician"
          show={addTechnicianModalView}
          onHide={() => {
            setaddTechnicianModalView(false);
          }}
          setCreds={setTechCreds}
          creds={techCreds}
          onHandleAdd={() => {
            handleAddTech();
          }}
          modalLoading={modalLoading}
          setLoadingModal={setModalLoading}
        />
      )}
    </section>
  );
};

export default ClientsListing;
