import { useEffect, useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import "./configurations.scss"; // Import the SCSS file
import { handleErrorResponse } from "../../helpers/responseHandler";
import { createNotification } from "../../helpers/createNotifications";
import { use } from "react";
import httpClient from "../../services/axios";

const Configurations = ({ tabOf, setdashboardView }) => {
  const [configurations, setConfigurations] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await httpClient.put("/api/v1/tenant_configurations", {
        tenant_configurations: {
          otp_receiving_email: configurations.otp_receiving_email,
        },
      });
      if (res.data) {
        createNotification("success", "Configurations updated successfully");
      }
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setLoading(false);
    }
  };

  const getCongifurations = async () => {
    setLoading(true);
    try {
      const res = await httpClient.get(`/api/v1/tenant_configurations`);
      if (res?.data) {
        setConfigurations(res?.data);
      }
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    getCongifurations();
  }, []);

  return (
    <Container className="container-config">
      <Card className="card-config">
        <Card.Body>
          <Card.Title className="card-title">Configurations</Card.Title>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="form-label">
                OTP Recipient Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                className="form-input"
                value={configurations?.otp_receiving_email}
                onChange={(e) =>
                  setConfigurations((prev) => ({
                    ...prev,
                    otp_receiving_email: e.target.value,
                  }))
                }
                required
              />
            </Form.Group>
            <div className="button-container">
              <Button
                variant="primary"
                className="button-save lm-form-btn-primary"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Configurations;
