import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import lmLogo from "../../assets/images/lm-logo-white.svg";
import userProfile1 from "../../assets/images/profile.png";
import collapse from "../../assets/svg/collapseright.svg";
import staricon from "../../assets/svg/star-icon.svg";
import ImageView from "../../components/Image/ImageView";
import Styles from "./Dashboard.module.scss";

import { useMatch, useNavigate, useParams } from "react-router-dom";
import ViewTechHoursModal from "../../components/Modals/ViewTechHoursModal";
import NotificationTab from "../../components/NotificationTab/NotificationTab";
import {
  AnalysisDashboardIcon,
  AppointmentsIcon,
  ClientBillingIcon,
  ClientsIcon,
  ConfigurationIcon,
  DashboardIcon,
  MyHrsOverviewIcon,
  NotificationsIcon,
  logoutIcon,
  settignsIcon,
} from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { GetCurrentUser, GetUserProfile } from "../../utils/utilAuth";

const DashboardSideBar = ({ isView, setdashboardView }) => {
  const user = GetCurrentUser();
  const [currentView, setCurrentView] = useState(() =>
    user?.role === "admin_support" ? "appointments" : "dashboard"
  );
  const setActiveTab = (tab) => {
    setCurrentView(tab);
  };
  const navigate = useNavigate();
  const { params } = useMatch("*");
  const { id, userId, appointId, serviceId, dataSheetId } = useParams();
  const route = Object.values(params)[0];
  const [notificationData, setNotificationData] = useState();
  const [actionDone, setActionDone] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState();
  const [userProfile, setUserProfile] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState();
  const [viewHoursModal, setViewHoursModal] = useState(false);

  const profile = GetUserProfile();
  useEffect(() => {
    setUserProfile(profile);
  }, [profile]);

  const activeClass = (tab) => {
    // setCurrentView(tab);
    return tab && tab == currentView ? "active" : "";
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "none",
      cursor: "pointer",
      paddingLeft: "5px",
      height: "40px",
      fontSize: "16px",
      outline: "none",
      boxShadow: "none",
      WebkitAppearance: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#666f78"
        : state.isFocused
        ? "#77b0ea"
        : "transparent",
      color: state.isSelected ? "white" : "black",
      padding: "10px",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f4f5fe",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "150px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const options = [
    { value: "Directors", label: "Directors" },
    { value: "admin-supporters", label: "Admin Supporters" },
    { value: "Clients", label: "Clients" },
    { value: "Technicians", label: "Technicians" },
  ];

  const renderListItem = ({
    dashboardView,
    icon,
    path,
    title,
    activeTab,
    tab,
  }) => (
    <li
      onClick={() => {
        setdashboardView(dashboardView);
        setActiveTab(activeTab);
        navigate(path);
      }}
      className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
        Styles.list_items
      } ${activeClass(tab)}`}
    >
      {icon}
      <span
        className={`f-15 f-w-500 lh-21 clr-white ${Styles.Close_Nav_Element}`}
      >
        {" "}
        {title}
      </span>
    </li>
  );

  useEffect(() => {
    // setLoading(true);
    const getUserDetails = async () => {
      try {
        const response = await httpClient.get(`/api/v1/users/${user?.slug}`);
        if (response?.data) {
          setUserProfile(response?.data);
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.data?.response.error === "Access denied" ||
          error?.response?.status === 401 ||
          error?.message === "Cannot read properties of null (reading 'user')"
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );

          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");

          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
        return false;
      }
    };
    if (!userProfile) {
      getUserDetails();
    }
  }, [user]);
  useEffect(() => {
    const getNotificationsData = async () => {
      try {
        // if (!notificationData) {
        const response = await httpClient.get(
          `api/v1/notifications?id=${
            user?.role === "technician" ? user?.slug : "learn-me-admin"
          }&page=1&per_page=10`
        );
        if (response?.data) {
          setNotificationData(response?.data?.data);
          setMetaData(response.data.meta);
        }

        return response;
        // }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    getNotificationsData();
  }, [actionDone]);

  useEffect(() => {
    setUnreadNotification(
      notificationData?.some((data) => data?.is_read === false)
    );
  }, [notificationData]);

  useEffect(() => {
    switch (route) {
      case "clients":
        setdashboardView("Clients");
        setActiveTab("Clients");
        break;
      case "directors":
        setdashboardView("Directors");
        setActiveTab("Directors");
        break;
      case "admin-supporters":
        setdashboardView("admin-supporters");
        setActiveTab("admin-supporters");
        break;
      case "clientBilling":
        setdashboardView("ClientBilling");
        setActiveTab("ClientBilling");
        break;
      case "configurations":
        setdashboardView("Configurations");
        setActiveTab("Configurations");
        break;
      case "analysisDashboard":
        setdashboardView("analysisDashboard");
        setActiveTab("analysisDashboard");
        break;
      case `clients/${id}`:
        setdashboardView("Client");
        setActiveTab("Clients");
        break;
      case `clients/goals`:
        setdashboardView("ViewGoals");
        setActiveTab("Clients");
        break;
      case "technicians":
        setdashboardView("Technicians");
        setActiveTab("Technicians");
        break;
      case `technicians/${id}`:
        setdashboardView("Technician");
        setActiveTab("Technicians");
        break;

      case "appointments":
        setdashboardView("Appointments");
        setActiveTab("Appointments");
        break;
      case "dashboard":
        setdashboardView("panel");
        setActiveTab("Dashboard");
        break;
      case "techdashboard":
        setdashboardView("panel");
        setActiveTab("Dashboard");
        break;

      case "techdashboard/sessions":
        setdashboardView("sessionCompleted");
        setActiveTab("Dashboard");
        break;
      case `clients/${id}/units`:
        setdashboardView("Units");
        setActiveTab("Clients");
        break;
      case `clients/${id}/documents`:
        setdashboardView("ClientDocuments");
        setActiveTab("Clients");
        break;
      case "techdashboard/pendingsessions":
        setdashboardView("pendingsessions");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/datasheet/${id}/${userId}/${appointId}`:
        setdashboardView("datasheet");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/serviceNote/${id}/${serviceId}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("Dashboard");
        break;
      case `yourClients`:
        setdashboardView("yourClients");
        setActiveTab("yourClients");
        break;
      case `yourClients/goals/${id}`:
        setdashboardView("clientGoals");
        setActiveTab("yourClients");
        break;
      case `yourClients/${id}`:
        setdashboardView("yourclientsingle");
        setActiveTab("yourClients");
        break;
      case `yourClients/datasheet/${id}/${userId}/${appointId}/${serviceId}`:
        setdashboardView("datasheet");
        setActiveTab("yourClients");
        break;
      case `yourClients/serviceNote/${id}/${serviceId}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("yourClients");
        break;
      case `clients/${id}/sessions`:
        setdashboardView("yourclientsingle");
        setActiveTab("Clients");
        break;
      case `technicians/${id}/sessions`:
        setdashboardView("yourTechsingle");
        setActiveTab("Technicians");
        break;
      case `dashboard/datasheet/${id}/${userId}/${appointId}/${serviceId}`:
        setdashboardView("datasheet");
        setActiveTab("Clients");
        break;
      case `dashboard/serviceNote/${id}/${serviceId}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("Clients");
        break;
      case `dashboardT/datasheet/${id}/${userId}/${appointId}/${serviceId}`:
        setdashboardView("datasheet");
        setActiveTab("Technicians");
        break;
      case `dashboardT/serviceNote/${id}/${serviceId}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("Technicians");
        break;
      case `appointments/serviceNote/${id}/${serviceId}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("Appointments");
        break;
      case `dashboard/sessions`:
        setdashboardView("Sessions");
        setActiveTab("Dashboard");
        break;
      case `technicians/inprogressgoals`:
        setdashboardView("InProgressGoals");
        setActiveTab("Technicians");
        break;
      case `techdashboard/goalsObjective/:id/:userId`:
        setdashboardView("goalsObjective");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/checkout/${dataSheetId}/${userId}/${id}`:
        setdashboardView("checkout");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/servicenote/${id}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/checkin/${dataSheetId}/${userId}/${id}`:
        setdashboardView("checkin");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/serviceNote/${id}/${dataSheetId}/${serviceId}`:
        setdashboardView("serviceNote");
        setActiveTab("Dashboard");
        break;
      case `techdashboard/checkin/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkin");
        setActiveTab("Dashboard");
        break;
      case `dashboard/checkin/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkin");
        setActiveTab("Clients");
        break;
      case `dashboard/checkout/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkout");
        setActiveTab("Clients");
        break;
      case `techdashboard/checkout/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkout");
        setActiveTab("Dashboard");
        break;
      case `yourClients/checkout/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkout");
        setActiveTab("yourClients");
        break;
      case `yourClients/checkin/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkin");
        setActiveTab("yourClients");
        break;
      case `techdashboard/dataSheet/${id}/${userId}/${appointId}/${serviceId}`:
        setdashboardView("datasheet");
        setActiveTab("Technicians");
        break;
      case `techdashboard/dataSheet/${id}/${userId}/${appointId}/${serviceId}`:
        setdashboardView("datasheet");
        setActiveTab("Technicians");
        break;
      case `yourClients/servicenote/${id}/${dataSheetId}`:
        setdashboardView("serviceNote");
        setActiveTab("yourClients");
        break;
      case `dashboardT/checkout/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkout");
        setActiveTab("Technicians");
        break;
      case `dashboardT/checkin/${dataSheetId}/${userId}/${id}/${serviceId}`:
        setdashboardView("checkin");
        setActiveTab("Technicians");
        break;
      case `techdashboard/goalsObjective/${id}/${userId}`:
        setdashboardView("goalsObjective");
        setActiveTab("Dashboard");
        break;
      default:
        return;
    }
  }, [params]);

  const setUserView = (view) => {
    // setCurrentView(tab);
    setdashboardView(view);
    let navigationView = view.toLowerCase();
    navigate(`/${navigationView}`);
  };

  const wrapperRef = useRef(null);
  const [NotificationView, SetNotificationView] = useState(false);
  const [UnreadNotifications, SetUnreadNotifications] = useState(true);
  const [navClose, setNavClose] = useState(false);

  const handleClick = () => {
    setNavClose(!navClose);
  };

  useEffect(() => {
    if (user.role === "technician") {
      setNavClose(true);
    }
  }, [isView, setdashboardView]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActionDone(!actionDone);
          SetNotificationView(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  const handleReadNotification = async () => {
    try {
      const res = await httpClient.get(
        `/api/v1/notifications/mark_as_completed?id=${
          user.role === "technician" ? user.slug : "learn-me-admin"
        }`
      );
      if (res.data) {
        setCurrentPage(1);
        setActionDone(!actionDone);
      }
      // }
      return true;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentView]);

  return (
    <section
      className={`${Styles.Dashboard__Sidebar} ${
        navClose ? Styles.Dashboard__Sidebar__Close_Nav_Bar : ""
      }`}
    >
      <div className={Styles.Dashboard__Sidebar_collapseiconwrapper}>
        <div className={Styles.Dashboard__Sidebar_collapseicon}>
          <img
            src={collapse}
            onClick={handleClick}
            className={Styles.Dashboard__Sidebar__Close_Icon}
          />
        </div>
      </div>
      <div className={Styles.Dashboard__Sidebar_Wrapper}>
        <ImageView
          src={lmLogo}
          className={`mb-3 img-fluid ${Styles.Close_Nav_Element}`}
        />
        <div className={`d-none ${Styles.StarIcon} `}>
          <img src={staricon} />
        </div>
        <div
          className={`d-flex flex-column gap-19 align-items-center ${Styles.Close_Nav_Element} ${Styles.Dashboard__Sidebar__ProfileI}`}
        >
          <div className={Styles.Dashboard__Sidebar__ProfileImageSection}>
            <ImageView
              src={userProfile?.profile_image || userProfile1}
              alt="user-profle"
              className={Styles.Dashboard__Sidebar__ProfileImageSection_Image}
            />
            <div
              onClick={(e) => {
                setdashboardView("ProfileSetting");
                setActiveTab("");
              }}
              className={
                Styles.Dashboard__Sidebar__ProfileImageSection_SettingsIcon
              }
            >
              {settignsIcon}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <h3 className="f-18 f-w-600 lh-21 clr-white-clr">
              {userProfile?.full_name}
            </h3>
            <h4 className="f-16 f-w-300 lh-21 clr-white-clr">
              {user.role === "director"
                ? "Clinical Director"
                : user.role === "admin_supporter"
                ? "Admin Supporter"
                : "Technician"}
            </h4>
          </div>
        </div>
        {user.role === "director" ? (
          <div className="w-100 DashboardSidebar">
            <ul className={Styles.Dashboard__Sidebar__Listing}>
              {renderListItem({
                dashboardView: "panel",
                icon: DashboardIcon,
                path: "/dashboard",
                title: "Dashboard",
                activeTab: "Dashboard",
                tab: "Dashboard",
              })}

              {renderListItem({
                dashboardView: "analysisDashboard",
                icon: AnalysisDashboardIcon,
                path: "/analysisDashboard",
                title: "Analysis Dashboard",
                activeTab: "analysisDashboard",
                tab: "analysisDashboard",
              })}

              <li
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("users")}`}
                onClick={(e) => {
                  setActiveTab("users");
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {ClientsIcon}
                  <Select
                    styles={customSelectStyles}
                    options={options}
                    placeholder="Users"
                    onChange={(e) => {
                      setActiveTab("users");
                      setUserView(e.value);
                    }}
                  />
                </div>
              </li>

              {renderListItem({
                dashboardView: "Appointments",
                icon: AppointmentsIcon,
                path: "/appointments",
                title: "Appointments",
                activeTab: "Appointments",
                tab: "Appointments",
              })}

              {renderListItem({
                dashboardView: "ClientBilling",
                icon: ClientBillingIcon,
                path: "/clientBilling",
                title: "Client Billing",
                activeTab: "ClientBilling",
                tab: "ClientBilling",
              })}

              {renderListItem({
                dashboardView: "Configurations",
                icon: ConfigurationIcon,
                path: "/configurations",
                title: "Configurations",
                activeTab: "Configurations",
                tab: "Configurations",
              })}

              <li
                onClick={() => {
                  SetNotificationView(!NotificationView);
                  // SetUnreadNotifications(!UnreadNotifications);
                  setActiveTab("Notifications");
                  setActionDone(!actionDone);
                }}
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("Notifications")}`}
              >
                <div
                  className={`notfications-Icon position-relative ${
                    unreadNotification && "unread"
                  }`}
                >
                  {NotificationsIcon}
                </div>
                <span
                  className={`f-15 f-w-500 lh-21 clr-white ${Styles.Close_Nav_Element}`}
                >
                  Notifications
                </span>
              </li>
            </ul>
          </div>
        ) : user.role == "admin_supporter" ? (
          <div className="w-100 DashboardSidebar">
            <ul className={Styles.Dashboard__Sidebar__Listing}>
              {renderListItem({
                dashboardView: "Appointments",
                icon: AppointmentsIcon,
                path: "/appointments",
                title: "Appointments",
                activeTab: "Appointments",
                tab: "Appointments",
              })}

              {renderListItem({
                dashboardView: "Clients",
                icon: ClientsIcon,
                path: "/clients",
                title: "Clients",
                activeTab: "Clients",
                tab: "Clients",
              })}
            </ul>
          </div>
        ) : (
          <div className="w-100 DashboardSidebar">
            <ul className={Styles.Dashboard__Sidebar__Listing}>
              <li
                onClick={(e) => {
                  setdashboardView("panel");
                  setActiveTab("Dashboard");
                  navigate("/dashboard");
                }}
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("Dashboard")}`}
              >
                {DashboardIcon}
                <span
                  className={`f-16 f-w-400 lh-21 clr-white ${Styles.Close_Nav_Element}`}
                >
                  {" "}
                  Dashboard
                </span>
              </li>
              <li
                onClick={() => {
                  setdashboardView("yourClients");
                  setActiveTab("yourClients");
                  navigate("/yourClients");
                }}
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("yourClients")}`}
              >
                {ClientsIcon}
                <span
                  className={`f-16 f-w-400 lh-21 clr-white ${Styles.Close_Nav_Element}`}
                >
                  Your Clients
                </span>
              </li>

              <li
                onClick={() => {
                  setViewHoursModal(true);
                }}
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("MyHrsOverview")}`}
              >
                {MyHrsOverviewIcon}
                <span
                  className={`f-16 f-w-400 lh-21 clr-white ${Styles.Close_Nav_Element}`}
                >
                  Hours Overview
                </span>
              </li>

              <li
                onClick={() => {
                  SetNotificationView(!NotificationView);
                  SetUnreadNotifications(!UnreadNotifications);
                  setActiveTab("Notifications");
                  setActionDone(!actionDone);
                }}
                className={`${Styles.Dashboard__Sidebar__Listing_ListItem} ${
                  Styles.list_items
                } ${activeClass("Notifications")}`}
              >
                <div
                  className={`notfications-Icon position-relative ${
                    unreadNotification && "unread"
                  }`}
                >
                  {NotificationsIcon}
                </div>
                <span
                  className={`f-16 f-w-400 lh-21 clr-white ${Styles.Close_Nav_Element}`}
                >
                  Notifications
                </span>
              </li>
            </ul>
          </div>
        )}
        <div
          className="logout-button mt-auto pb-32"
          onClick={() => {
            localStorage.removeItem(`persist:root`);
            logout();
            window.open("/dashboardlogin", "_self");
          }}
        >
          <div>{logoutIcon}</div>
          <span
            className={`f-16 f-w-400 lh-21 clr-white ${Styles.Close_Nav_Element}`}
          >
            Logout
          </span>
        </div>
      </div>
      {NotificationView && (
        <NotificationTab
          refr={wrapperRef}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          unreadNotification={unreadNotification}
          Notifications={notificationData}
          setActionDone={setActionDone}
          actionDone={actionDone}
          setNotificationData={setNotificationData}
          metaData={metaData}
          setMetaData={setMetaData}
          handleReadNotification={handleReadNotification}
        />
      )}

      <ViewTechHoursModal
        show={viewHoursModal}
        onHide={() => {
          setViewHoursModal(false);
        }}
        technicianID={user?.slug}
      />
    </section>
  );
};

export default DashboardSideBar;
